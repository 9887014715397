.upperNav {
  width: 100%;
  z-index: 2;
}

body {
  padding-top: 0px;  /* adjust this value according to your .upperNav and .lowerNav height */
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin: 0 auto;
  width: 90%;
}

.brandText {
  font-size: .9em;
  font-weight: bold;
  color: #333333;
}
