/*  */

/* General Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f5f7fa;
  color: #333;
  line-height: 1.6;
}

button {
  cursor: pointer;
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #3a7bc8;
}

button:disabled {
  background-color: #a9c0e2;
  cursor: not-allowed;
}

/* Login Styles */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f7fa;
}

.login-box {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 400px;
}

.login-box h1 {
  text-align: center;
  margin-bottom: 24px;
  color: #2e3e50;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group input:focus {
  border-color: #1976d2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.1);
}

.error-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  text-align: center;
}

.success-message {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  text-align: center;
}

/* Dashboard Styles */
.dashboard-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0 80px 0;
}

.dashboard-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  background-color: #4a90e2;
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-email {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
}

.dashboard-content {
  background-color: white;
  min-height: calc(100vh - 140px);
  padding: 12px;
}

/* Account section styles */
.account-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-profile {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.user-profile h2 {
  margin-bottom: 20px;
  color: #2e3e50;
  font-size: 22px;
}

.account-details {
  margin-bottom: 25px;
  text-align: left;
}

.account-email,
.account-role {
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
}

.account-email strong,
.account-role strong {
  display: inline-block;
  width: 80px;
  color: #7f8c8d;
}

.user-profile .logout-btn {
  background-color: #e74c3c;
  color: white;
  padding: 12px 20px;
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 6px;
}

.user-profile .logout-btn:hover {
  background-color: #c0392b;
}

/* Tab Navigation Styles */
.tab-navigation {
  display: flex;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eaeaea;
  background-color: #fff;
}

.tab-navigation button {
  flex: 1;
  padding: 15px;
  border-radius: 0;
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
  border: none;
  border-bottom: 3px solid transparent;
  transition: all 0.2s ease;
}

.tab-navigation button.active {
  background-color: #fff;
  border-bottom: 3px solid #4a90e2;
  color: #4a90e2;
}

.tab-navigation button:hover:not(.active) {
  background-color: #e9ecef;
}

.tab-content {
  min-height: 400px;
}

.actions-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 1200px;
  margin: 0 auto 20px;
  padding: 0 12px;
}

.approve-btn {
  background-color: #27ae60;
}

.approve-btn:hover {
  background-color: #219653;
}

.loading-spinner {
  text-align: center;
  padding: 30px;
  color: #7f8c8d;
}

.no-data {
  text-align: center;
  padding: 30px;
  color: #7f8c8d;
  background-color: #f9f9f9;
  border-radius: 4px;
}

/* User Card Styles */
.users-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
}

.user-card {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  transition: box-shadow 0.3s ease;
}

.user-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.user-card.conflict {
  border-color: #e74c3c;
}

.user-header {
  display: flex;
  padding: 15px;
  background-color: #f8fafc;
  border-bottom: 1px solid #eaeaea;
}

.user-avatar {
  margin-right: 15px;
}

.user-avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.placeholder-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #4a90e2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.user-info h3 {
  margin-bottom: 5px;
  color: #2e3e50;
}

.conflict-badge {
  display: inline-block;
  background-color: #e74c3c;
  color: white;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 100px;
  margin-top: 8px;
}

.matches-container {
  padding: 15px;
}

.matches-container h4 {
  margin-bottom: 15px;
  color: #2e3e50;
  font-size: 16px;
}

.no-matches {
  color: #7f8c8d;
  font-style: italic;
  padding: 10px 0;
}

.db-user-card {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.db-user-details {
  flex: 1;
}

.db-user-details div {
  margin-bottom: 6px;
  font-size: 14px;
}

.db-user-actions {
  margin-left: 15px;
}

button.selected {
  background-color: #27ae60;
}

/* Social Events Styles */
.events-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.days-filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.days-filter label {
  font-weight: 600;
}

.days-filter input {
  width: 70px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-left: auto;
}

.calendar-btn {
  background-color: #9c27b0;
}

.calendar-btn:hover {
  background-color: #7b1fa2;
}

.events-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  padding: 8px 4px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Event Cards */
.event-card {
  border: 1px solid #eaeaea;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
}

.event-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.event-card.expanded {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.event-card.posted {
  border-color: #27ae60;
  border-width: 2px;
}

.event-card.private {
  border-color: #f39c12;
  border-width: 2px;
}

.event-card button {
  position: relative;
  z-index: 2;
}

.event-header {
  padding: 16px 18px;
  background-color: #f8fafc;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.event-title {
  flex: 1;
}

.event-title h3 {
  margin-bottom: 5px;
  color: #2e3e50;
  font-size: 18px;
  line-height: 1.3;
}

.event-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 8px;
  color: #666;
  font-size: 14px;
  line-height: 1.4;
}

.event-date {
  display: none; /* Hide the full date format */
}

.event-short-date {
  font-weight: 600;
  color: #4a90e2;
}

.event-time {
  color: #333;
}

.event-duration {
  color: #666;
  font-style: italic;
}

.event-actions {
  margin-left: 10px;
}

.event-badge {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.posted-badge {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.private-badge {
  background-color: #fff3e0;
  color: #e65100;
}

.event-details {
  padding: 16px 18px;
  line-height: 1.5;
}

.event-details > div {
  margin-bottom: 12px;
}

.event-location {
  font-size: 15px;
}

/* Event description with animation */
.event-description {
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
  margin-top: 10px;
  font-size: 14px;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.event-chat {
  font-size: 14px;
  color: #7f8c8d;
}

.event-id {
  margin-top: 10px;
  font-size: 12px;
  color: #95a5a6;
}

/* Calendar Results Styles */
.calendar-results {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
}

.calendar-results h3 {
  margin-bottom: 15px;
  color: #2e3e50;
}

.results-summary {
  margin-bottom: 20px;
  font-size: 16px;
}

.result-item {
  background-color: white;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  border-left: 4px solid #ddd;
}

.result-item.success {
  border-left-color: #27ae60;
}

.result-item.failure {
  border-left-color: #e74c3c;
}

.result-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.result-status {
  font-weight: 600;
}

.result-id {
  font-family: monospace;
  color: #7f8c8d;
}

.result-message,
.result-error {
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
}

.result-message {
  background-color: #f8f9fa;
  color: #495057;
}

.result-error {
  background-color: #ffebee;
  color: #d32f2f;
}

/* Cognito Authentication Styles */
.auth-selector {
  display: flex;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eaeaea;
}

.auth-selector button {
  flex: 1;
  padding: 12px;
  border-radius: 0;
  background-color: #f5f7fa;
  color: #2e3e50;
  font-weight: 600;
  border: none;
  border-bottom: 3px solid transparent;
}

.auth-selector button.active {
  background-color: #fff;
  border-bottom: 3px solid #4a90e2;
  color: #4a90e2;
}

.auth-selector button:hover:not(.active) {
  background-color: #edf2f7;
}

.cognito-login {
  text-align: center;
  padding: 20px 0;
}

.cognito-login p {
  margin-bottom: 20px;
  color: #7f8c8d;
}

.cognito-btn {
  background-color: #ff9900; /* AWS orange color */
  padding: 12px 20px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.cognito-btn:hover {
  background-color: #ec7211;
}

/* New login button style */
.login-btn {
  background-color: #4a90e2;
  padding: 12px 20px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.login-btn:hover {
  background-color: #3a7bc8;
}

/* Auth links styles */
.auth-links {
  margin-top: 20px;
  text-align: center;
  padding-top: 15px;
  border-top: 1px solid #eaeaea;
}

.signup-link {
  color: #4a90e2;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.signup-link:hover {
  color: #3a7bc8;
  text-decoration: underline;
}

.user-role {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.user-role.admin {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.user-role.member {
  background-color: #e3f2fd;
  color: #1976d2;
}

.user-role.user {
  background-color: #f5f5f5;
  color: #616161;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .users-list,
  .events-list {
    grid-template-columns: 1fr;
  }

  .actions-bar,
  .events-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .dashboard-header {
    padding: 12px 15px;
  }

  .dashboard-header h1 {
    font-size: 18px;
  }

  .user-email {
    display: none; /* Hide email on mobile for cleaner header */
  }

  /* Hide the top tabs when bottom tabs are active */
  .tab-navigation {
    display: none;
  }

  /* Make event cards more compact on mobile */
  .event-card {
    margin-bottom: 15px;
  }

  .event-header {
    padding: 14px 16px;
  }

  .event-title h3 {
    font-size: 16px;
  }

  .event-details {
    padding: 14px 16px;
  }

  /* Make bottom tabs stick to bottom even when scrolling */
  .mobile-tab-navigation {
    position: fixed;
    bottom: 0;
    height: 60px;
  }

  .days-filter {
    flex-wrap: wrap;
  }

  .days-filter label {
    width: 100%;
  }

  .days-filter input {
    width: 100%;
  }

  .dashboard-content {
    padding: 8px;
  }

  .events-list {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 4px;
  }
}

/* For very small screens */
@media (max-width: 320px) {
  .mobile-tab-navigation button {
    padding: 5px 0;
  }

  .tab-icon {
    font-size: 20px;
  }

  .tab-label {
    font-size: 10px;
  }
}

.switch-auth-btn {
  background: none;
  border: none;
  color: #1976d2;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  padding: 5px;
  text-decoration: underline;
}

.switch-auth-btn:hover {
  color: #1565c0;
}

.password-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.password-input-container input[type="password"],
.password-input-container input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.show-password-label {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  padding: 4px 0;
  user-select: none;
  line-height: 1;
}

.show-password-label input[type="checkbox"] {
  margin: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #1976d2;
  position: relative;
  top: 1px;
}

.show-password-label:hover {
  color: #1976d2;
}

.show-password-label:hover input[type="checkbox"] {
  accent-color: #1565c0;
}

.password-requirements {
  font-size: 12px;
  color: #666;
  margin-top: 8px;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.password-requirements ul {
  margin: 4px 0 0 20px;
  padding: 0;
}

.password-requirements li {
  margin: 2px 0;
  color: #666;
}

.password-requirements li::marker {
  color: #1976d2;
}

/* Verification form styles */
.verification-form {
  text-align: center;
  padding: 20px 0;
}

.verification-form h3 {
  margin-bottom: 15px;
  color: #2e3e50;
}

.verification-form p {
  margin-bottom: 20px;
  color: #7f8c8d;
}

.resend-code-btn {
  background-color: #95a5a6;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
}

.resend-code-btn:hover {
  background-color: #7f8c8d;
}

.resend-code-btn:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

/* Event styles */
.event-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.event-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.event-card:hover .event-image img {
  transform: scale(1.05);
}

/* Mobile App-like Bottom Navigation */
.mobile-tab-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
}

.mobile-tab-navigation button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: #95a5a6;
  border: none;
  padding: 8px 0;
  border-radius: 0;
  flex: 1;
  transition: color 0.3s ease;
}

.mobile-tab-navigation button.active {
  color: #4a90e2;
  background-color: transparent;
}

.tab-icon {
  font-size: 24px;
  margin-bottom: 4px;
}

.tab-label {
  font-size: 12px;
  font-weight: 500;
}

/* Empty state for new tabs */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  text-align: center;
}

.empty-state h2 {
  margin-bottom: 15px;
  color: #2e3e50;
}

.empty-state p {
  color: #7f8c8d;
  font-size: 16px;
}

/* Admin actions button */
.admin-actions {
  margin: 15px auto;
  max-width: 1200px;
  padding: 0 12px;
}

.admin-actions button {
  background-color: #9c27b0;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
}

.admin-actions button.active {
  background-color: #7b1fa2;
}

/* For small screens */
@media (max-width: 480px) {
  .events-list {
    padding: 0;
    gap: 12px;
  }

  .event-card {
    border-radius: 8px;
    margin-bottom: 0;
  }

  .event-badge {
    padding: 4px 8px;
    font-size: 11px;
  }
}

/* Event attendance indicator */
.event-attendance {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  color: #4a90e2;
  background-color: rgba(74, 144, 226, 0.1);
  padding: 2px 8px;
  border-radius: 20px;
}

.attendance-icon {
  margin-right: 4px;
  font-size: 13px;
}

/* See who's going button */
.see-attendees {
  margin-top: 15px;
}

.see-attendees-btn {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  transition: background-color 0.2s ease;
  text-align: center;
  cursor: pointer;
}

.see-attendees-btn:hover {
  background-color: #3a7bc8;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 420px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.3s ease;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: #4a90e2;
  color: white;
  border-radius: 8px 8px 0 0;
}

.modal-header h3 {
  margin: 0;
  font-size: 18px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-body {
  padding: 20px;
}

.modal-body p {
  margin-bottom: 16px;
  color: #333;
}

.modal-body ul {
  margin: 0 0 0 20px;
  padding: 0;
}

.modal-body li {
  margin-bottom: 10px;
  color: #666;
}

.modal-footer {
  padding: 15px 20px 20px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  border-top: 1px solid #eaeaea;
}

.subscribe-btn {
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
}

.cancel-btn {
  background-color: #f5f5f5;
  color: #666;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
}

.subscribe-btn:hover {
  background-color: #3a7bc8;
}

.cancel-btn:hover {
  background-color: #e5e5e5;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Subscription required screen */
.subscription-required {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.subscription-icon {
  font-size: 50px;
  margin-bottom: 20px;
}

.subscription-required h2 {
  color: #2e3e50;
  margin-bottom: 15px;
  font-size: 24px;
}

.subscription-required p {
  color: #666;
  margin-bottom: 15px;
  font-size: 16px;
}

.subscription-benefits {
  font-weight: 600;
  margin-top: 10px;
  color: #4a90e2;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.benefits-list li {
  background-color: #f8fafc;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: relative;
  padding-left: 30px;
  text-align: left;
}

.benefits-list li:before {
  content: "✓";
  color: #4a90e2;
  position: absolute;
  left: 12px;
  font-weight: bold;
}

.subscribe-btn-large {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0 3px 10px rgba(74, 144, 226, 0.3);
  transition: all 0.3s ease;
}

.subscribe-btn-large:hover {
  background-color: #3a7bc8;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(74, 144, 226, 0.4);
}

@media (max-width: 480px) {
  .subscription-required {
    padding: 30px 15px;
  }

  .benefits-list li {
    padding: 10px 10px 10px 30px;
    font-size: 14px;
  }

  .subscribe-btn-large {
    width: 100%;
  }
}
