/* Import the font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

/* Hero section */
.hero-banner {
  position: relative;
  height: 25vh;
  background-image: url("https://images.unsplash.com/photo-1612872087720-bb876e2e67d1?auto=format&fit=crop&q=80&w=2007");
  background-size: cover;
  background-position: center;
}

.hero-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.hero-content {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-content h1 {
  color: white;
  font-size: 2.5rem;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000; /* Thicker black outline */
  font-weight: 700; /* Bold */
}

.hosted-by {
  color: white;
  font-size: 1.25rem;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000; /* Thicker black outline */
  font-weight: 300; /* Light */
  margin-top: 0.5rem;
}

/* Light theme adjustments */
body {
  font-family: "Poppins", sans-serif;
  background-color: #f9fafb; /* Light background */
  color: #1f2937; /* Dark text */
}

.card {
  background-color: #ffffff; /* White card background */
  color: #1f2937; /* Dark text */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 2rem;
  margin-top: 2rem;
}

/* Ensure this is applied */
.flex.items-center {
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: start; /* Align items to the start horizontally */
  gap: 1rem; /* Add space between items */
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1e3a8a;
}

.disclaimer-card {
  background-color: #fff9ed;
  border-radius: 0.75rem;
  padding: 2rem;
  border: 1px solid #fde68a;
}

/* Icons */
.icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

/* Buttons and Links */
.signup-button {
  background-color: #2563eb;
  color: white;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 0.75rem;
  transition: all 0.2s;
  font-size: 1.125rem;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgb(37 99 235 / 0.2);
}

.signup-button:hover {
  background-color: #1d4ed8;
  transform: translateY(-1px);
  box-shadow: 0 6px 8px -1px rgb(37 99 235 / 0.3);
}

.contact-link {
  color: #2563eb;
  text-decoration: none;
  transition: color 0.2s;
}

.contact-link:hover {
  color: #1d4ed8;
  text-decoration: underline;
}

/* Layout */
.space-y-8 > * + * {
  margin-top: 2rem;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}

.space-x-3 > * + * {
  margin-left: 0.75rem;
}

/* Grid */
@media (min-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Responsive Images */
.responsive-img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

/* Footer */
.footer {
  text-align: center;
  padding: 1rem 0;
  background-color: #f9fafb;
  color: #1f2937;
}

/* Event Details */
.event-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Adjust the gap between items */
}

/* Card Enhancements */
.card {
  background-color: #ffffff;
  color: #1f2937;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-top: 2rem;
}

/* Card Title */
.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1e3a8a;
}

/* Centered text */
.text-center {
  text-align: center;
}
