@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Righteous&display=swap");

body {
  font-family: "Quicksand", sans-serif;
  background-color: #333333;
}

.font-display {
  font-family: "Righteous", cursive;
  font-size: 4rem;
  line-height: 1.1;
}

.home {
  font-family: sans-serif;
  text-align: center;
}

.card {
  line-height: 1.1;
}

.custom-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 3px solid purple;
  border-radius: 10px;
  padding: 7px;
  margin: 2px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.1;
  letter-spacing: 0.5px;
  color: #333;
  text-rendering: optimizeLegibility;
}

.button-content {
  flex-grow: 1;
}

.button-icon {
  margin-left: -20px;
}

/* Custom CSS for dark border */
.card-border-dark {
  border: 0px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 0px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.outer-home-container {
  /* display: flex; */
  /* flex-direction: column; */
  /* height: 100vh; */
  /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
}

.btn-default a {
  color: #333;
}

.btn-primary a {
  color: #fff;
}

.btn-default a:hover {
  text-decoration: none;
}
.btn-primary a:hover {
  text-decoration: none;
}

.parallax-section {
  position: relative;
  overflow: hidden;
}

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
  border: none;
  margin: 0;
}

.parallax-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  text-align: center;
}

.join-button,
.get-started-button {
  display: inline-block;
  background-color: white; /* Original background */
  color: black; /* Text color */
  padding: 8px 16px; /* Adjust padding for a snug fit */
  border-radius: 9999px; /* Fully rounded */
  font-size: 1.7rem;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
  text-align: center;
  padding: 8px 36px;
  margin-top: 40px;
}

.join-button:hover,
.get-started-button:hover {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly opaque on hover */
  color: black;
}

@media (max-width: 767px) {
  .photo-grid {
    padding: 2rem 0;
    color: #ffffff;
    background-color: #333333; /* Darker background color */
  }

  .photo-grid .group {
    overflow: hidden;
    border-radius: 0.5rem; /* Rounded corners */
    justify-content: center;
    align-items: center;
  }

  .photo-grid img {
    width: 100%; /* Fill the container width */
    height: 300px; /* Set a fixed height */
    object-fit: cover; /* Ensures the image covers the area */
    object-position: center; /* Centers the image */
    transition: transform 0.3s ease;
  }

  .group:hover img {
    transform: scale(1.05);
  }
}

@media (min-width: 768px) {
  .photo-grid {
    padding: 2rem 0;
    background-color: #f9fafb; /* Light gray background */
  }

  .photo-grid .group {
    overflow: hidden;
    border-radius: 0.5rem; /* Rounded corners */
  }

  .photo-grid img {
    width: 100%; /* Fill the container width */
    height: 300px; /* Set a fixed height */
    object-fit: cover; /* Ensures the image covers the area */
    transition: transform 0.3s ease;
  }

  .group:hover img {
    transform: scale(1.05);
  }

  .grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  }
}
