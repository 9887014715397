.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .footer {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  /* set font size for all button elements on page */
  .btn {
    font-size: '18px';
  }


  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* width: 100%; */
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }

  /* Define the error styling for input fields */
.input-field.error {
  border-color: red;
}

/* Define the error message styling */
.input-feedback {
  color: red;
  margin-top: 5px;
}

/* Style your fields normally */
.input-field {
  /* Your regular styles */

  /* style the input field to be full width */
  width: 100%;
}

/* Change the field border to red when an error message is present */
.input-feedback + .input-field {
  border-color: red;
}

/* Style your error messages */
.input-feedback {
  color: red;
}

/* style label to be above the input */
label {
  display: block;
  margin-bottom: 1px;
}

#description {
  font-size: 14px;
}