.create-event-page {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.back-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.back-button:hover {
  background-color: #0056b3;
}

h1 {
  margin: 0;
  font-size: 24px;
}

.content {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-item {
  margin-bottom: 20px;
}

.custom-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.custom-input,
.custom-textarea,
.custom-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.custom-input:focus,
.custom-textarea:focus,
.custom-select:focus {
  border-color: #007bff;
  outline: none;
}

textarea.custom-input {
  resize: vertical;
}

.choose-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  display: block;
  width: 100%;
  text-align: center;
}

.choose-button:hover {
  background-color: #0056b3;
}

.sub-label {
  color: grey;
  font-style: italic;
  font-size: 12px;
}

.submit-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  margin-bottom: 100px;
}

.submit-button:hover {
  background-color: #218838;
}

.success-message {
  text-align: center;
  color: #28a745;
  font-size: 18px;
  margin-bottom: 20px;
}

.large-checkmark {
  font-size: 48px;
  display: block;
  margin-bottom: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
}

.modal-body {
  max-height: 300px;
  overflow-y: auto;
}

.modal-body input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-body li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.modal-body li:hover {
  background-color: #f1f1f1;
}
