.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.benefits-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.benefits-table th,
.benefits-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center; /* Center align text */
}

.benefits-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  white-space: nowrap; /* Prevent wrapping */
}

.benefits-table .check {
  color: green !important; /* Green check marks */
}

.benefits-table .cross {
  color: red !important; /* Red cross marks */
}

/* Center align blue subscribe buttons */
.btn-primary {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 50% !important;
  text-align: center !important;
}

/* Center the StripeBuyButton */
.stripe-buy-button {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}
