.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .runimage {
    height: "200px"; /* auto adjust the height */
    margin-left: "auto";
    margin-right: "auto";
  }
  .footer {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  /* set font size for all button elements on page */
  .btn {
    font-size: '18px';
  }


  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* width: 100%; */
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }