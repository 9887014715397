@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap);
.App {
  font-family: sans-serif;
  text-align: center;
  /* max-width: 480px;
  margin: 0 auto; */
}
.btn,
#tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}

.footer {
  padding-bottom: 20px;
}

.upperNav {
  width: 100%;
  z-index: 2;
}

body {
  padding-top: 0px;  /* adjust this value according to your .upperNav and .lowerNav height */
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin: 0 auto;
  width: 90%;
}

.brandText {
  font-size: .9em;
  font-weight: bold;
  color: #333333;
}

.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  .card {
    line-height: 1.1;
  }

  .social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .link-container {
    width: 33%;
    align-items: center;
  }
  
  .social-link {
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-bottom: 5px;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
  
  .link-label {
    text-align: center;
    font-style: italic;
  }
  

  /* Custom CSS for dark border */
.card-border-dark {
  border: 1px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 1px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  transform: scale(1.02);
}



  
  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }
body {
  font-family: "Quicksand", sans-serif;
  background-color: #333333;
}

.font-display {
  font-family: "Righteous", cursive;
  font-size: 4rem;
  line-height: 1.1;
}

.home {
  font-family: sans-serif;
  text-align: center;
}

.card {
  line-height: 1.1;
}

.custom-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 3px solid purple;
  border-radius: 10px;
  padding: 7px;
  margin: 2px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.1;
  letter-spacing: 0.5px;
  color: #333;
  text-rendering: optimizeLegibility;
}

.button-content {
  flex-grow: 1;
}

.button-icon {
  margin-left: -20px;
}

/* Custom CSS for dark border */
.card-border-dark {
  border: 0px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 0px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
}

.outer-home-container {
  /* display: flex; */
  /* flex-direction: column; */
  /* height: 100vh; */
  /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
}

.btn-default a {
  color: #333;
}

.btn-primary a {
  color: #fff;
}

.btn-default a:hover {
  text-decoration: none;
}
.btn-primary a:hover {
  text-decoration: none;
}

.parallax-section {
  position: relative;
  overflow: hidden;
}

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
  border: none;
  margin: 0;
}

.parallax-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  text-align: center;
}

.join-button,
.get-started-button {
  display: inline-block;
  background-color: white; /* Original background */
  color: black; /* Text color */
  padding: 8px 16px; /* Adjust padding for a snug fit */
  border-radius: 9999px; /* Fully rounded */
  font-size: 1.7rem;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
  text-align: center;
  padding: 8px 36px;
  margin-top: 40px;
}

.join-button:hover,
.get-started-button:hover {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly opaque on hover */
  color: black;
}

@media (max-width: 767px) {
  .photo-grid {
    padding: 2rem 0;
    color: #ffffff;
    background-color: #333333; /* Darker background color */
  }

  .photo-grid .group {
    overflow: hidden;
    border-radius: 0.5rem; /* Rounded corners */
    justify-content: center;
    align-items: center;
  }

  .photo-grid img {
    width: 100%; /* Fill the container width */
    height: 300px; /* Set a fixed height */
    object-fit: cover; /* Ensures the image covers the area */
    object-position: center; /* Centers the image */
    transition: transform 0.3s ease;
  }

  .group:hover img {
    transform: scale(1.05);
  }
}

@media (min-width: 768px) {
  .photo-grid {
    padding: 2rem 0;
    background-color: #f9fafb; /* Light gray background */
  }

  .photo-grid .group {
    overflow: hidden;
    border-radius: 0.5rem; /* Rounded corners */
  }

  .photo-grid img {
    width: 100%; /* Fill the container width */
    height: 300px; /* Set a fixed height */
    object-fit: cover; /* Ensures the image covers the area */
    transition: transform 0.3s ease;
  }

  .group:hover img {
    transform: scale(1.05);
  }

  .grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  }
}

.App1 {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}
.App2 {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}

#invoiceAmount, #tipAmount, #description { font-size: 30px; }
.App3 {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}

.App3 > div > form > input:nth-child(n), #tipAmount { font-size: 30px; }
.pay {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.btn {
  width: 90%;
}
.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.benefits-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.benefits-table th,
.benefits-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center; /* Center align text */
}

.benefits-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  white-space: nowrap; /* Prevent wrapping */
}

.benefits-table .check {
  color: green !important; /* Green check marks */
}

.benefits-table .cross {
  color: red !important; /* Red cross marks */
}

/* Center align blue subscribe buttons */
.btn-primary {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 50% !important;
  text-align: center !important;
}

/* Center the StripeBuyButton */
.stripe-buy-button {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.page {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}
.Terms {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}
.Privacy {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}
.page {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  /* Custom CSS for dark border */
.card-border-dark {
  border: 1px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 1px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  transform: scale(1.02);
}



  
  .outer-page-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }
.create-event-page {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.back-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.back-button:hover {
  background-color: #0056b3;
}

h1 {
  margin: 0;
  font-size: 24px;
}

.content {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-item {
  margin-bottom: 20px;
}

.custom-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.custom-input,
.custom-textarea,
.custom-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.custom-input:focus,
.custom-textarea:focus,
.custom-select:focus {
  border-color: #007bff;
  outline: none;
}

textarea.custom-input {
  resize: vertical;
}

.choose-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  display: block;
  width: 100%;
  text-align: center;
}

.choose-button:hover {
  background-color: #0056b3;
}

.sub-label {
  color: grey;
  font-style: italic;
  font-size: 12px;
}

.submit-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  margin-bottom: 100px;
}

.submit-button:hover {
  background-color: #218838;
}

.success-message {
  text-align: center;
  color: #28a745;
  font-size: 18px;
  margin-bottom: 20px;
}

.large-checkmark {
  font-size: 48px;
  display: block;
  margin-bottom: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
}

.modal-body {
  max-height: 300px;
  overflow-y: auto;
}

.modal-body input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-body li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.modal-body li:hover {
  background-color: #f1f1f1;
}

.page {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  /* .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    margin-top: 600px;

  } */
  
/* form-control */
.form-control {
  font-size: 14px;
  font-family: 'Comfortaa';
}

/* h3 elements inside form class need to have good font for bold text */
.form h3, .form label {
  font-family: 'Comfortaa';
  font-weight: bold;
}

/* label elements inside form class should have same font */
.form label {
  font-family: 'Comfortaa';
  font-size: 14px;
}

  /* Custom CSS for dark border */
.card-border-dark {
  border: 1px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 1px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  transform: scale(1.02);
}



  
  .outer-page-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }
.page {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  /* .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    margin-top: 600px;

  } */
  

  /* Custom CSS for dark border */
.card-border-dark {
  border: 1px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 1px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  transform: scale(1.02);
}



  
  .outer-page-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }
/* .auth {
  background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
} */

.login {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  font-family: 'Comfortaa', cursive;
}

.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
}

.form input, .form textarea {
  outline: 0;
  background: #fff;
  width: 100%;
  border: 1px solid #d1d1d1; /* Faint grey color */;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Comfortaa', cursive;
}

.form input:focus {
  background: #dbdbdb;
}

.form button {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  outline: 0;
  background: #4b6cb7;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:active {
  background: #395591;
}

.form span {
  font-size: 40px;
  color: #4b6cb7;
  margin-bottom: 25px;
  display: block;
}

.form p.error {
  margin: 0 0 10px 10px;
  text-align: left;
  font-size: 10px;
  color: red;
}
.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .footer {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  /* set font size for all button elements on page */
  .btn {
    font-size: '18px';
  }


  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* width: 100%; */
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }

  /* Define the error styling for input fields */
.input-field.error {
  border-color: red;
}

/* Define the error message styling */
.input-feedback {
  color: red;
  margin-top: 5px;
}

/* Style your fields normally */
.input-field {
  /* Your regular styles */

  /* style the input field to be full width */
  width: 100%;
}

/* Change the field border to red when an error message is present */
.input-feedback + .input-field {
  border-color: red;
}

/* Style your error messages */
.input-feedback {
  color: red;
}

/* style label to be above the input */
label {
  display: block;
  margin-bottom: 1px;
}

#description {
  font-size: 14px;
}
.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .footer {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  /* set font size for all button elements on page */
  .btn {
    font-size: '18px';
  }


  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* width: 100%; */
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }

  /* Define the error styling for input fields */
.input-field.error {
  border-color: red;
}

/* Define the error message styling */
.input-feedback {
  color: red;
  margin-top: 5px;
}

/* Style your fields normally */
.input-field {
  /* Your regular styles */

  /* style the input field to be full width */
  width: 100%;
}

/* Change the field border to red when an error message is present */
.input-feedback + .input-field {
  border-color: red;
}

/* Style your error messages */
.input-feedback {
  color: red;
}

/* style label to be above the input */
label {
  display: block;
  margin-bottom: 1px;
}

#description {
  font-size: 14px;
}
.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .runimage {
    height: "200px"; /* auto adjust the height */
    margin-left: "auto";
    margin-right: "auto";
  }
  .footer {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  /* set font size for all button elements on page */
  .btn {
    font-size: '18px';
  }


  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* width: 100%; */
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }
.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
/* Import the font */

/* Hero section */
.hero-banner {
  position: relative;
  height: 25vh;
  background-image: url("https://images.unsplash.com/photo-1612872087720-bb876e2e67d1?auto=format&fit=crop&q=80&w=2007");
  background-size: cover;
  background-position: center;
}

.hero-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.hero-content {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-content h1 {
  color: white;
  font-size: 2.5rem;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000; /* Thicker black outline */
  font-weight: 700; /* Bold */
}

.hosted-by {
  color: white;
  font-size: 1.25rem;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000; /* Thicker black outline */
  font-weight: 300; /* Light */
  margin-top: 0.5rem;
}

/* Light theme adjustments */
body {
  font-family: "Poppins", sans-serif;
  background-color: #f9fafb; /* Light background */
  color: #1f2937; /* Dark text */
}

.card {
  background-color: #ffffff; /* White card background */
  color: #1f2937; /* Dark text */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 2rem;
  margin-top: 2rem;
}

/* Ensure this is applied */
.flex.items-center {
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: start; /* Align items to the start horizontally */
  grid-gap: 1rem;
  gap: 1rem; /* Add space between items */
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1e3a8a;
}

.disclaimer-card {
  background-color: #fff9ed;
  border-radius: 0.75rem;
  padding: 2rem;
  border: 1px solid #fde68a;
}

/* Icons */
.icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

/* Buttons and Links */
.signup-button {
  background-color: #2563eb;
  color: white;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 0.75rem;
  transition: all 0.2s;
  font-size: 1.125rem;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgb(37 99 235 / 0.2);
}

.signup-button:hover {
  background-color: #1d4ed8;
  transform: translateY(-1px);
  box-shadow: 0 6px 8px -1px rgb(37 99 235 / 0.3);
}

.contact-link {
  color: #2563eb;
  text-decoration: none;
  transition: color 0.2s;
}

.contact-link:hover {
  color: #1d4ed8;
  text-decoration: underline;
}

/* Layout */
.space-y-8 > * + * {
  margin-top: 2rem;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}

.space-x-3 > * + * {
  margin-left: 0.75rem;
}

/* Grid */
@media (min-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    gap: 3rem;
  }
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Responsive Images */
.responsive-img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

/* Footer */
.footer {
  text-align: center;
  padding: 1rem 0;
  background-color: #f9fafb;
  color: #1f2937;
}

/* Event Details */
.event-details {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem; /* Adjust the gap between items */
}

/* Card Enhancements */
.card {
  background-color: #ffffff;
  color: #1f2937;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-top: 2rem;
}

/* Card Title */
.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1e3a8a;
}

/* Centered text */
.text-center {
  text-align: center;
}

.page {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  /* .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    margin-top: 600px;

  } */
  
/* form-control */
.form-control {
  font-size: 14px;
  font-family: 'Comfortaa';
}

/* h3 elements inside form class need to have good font for bold text */
.form h3, .form label {
  font-family: 'Comfortaa';
  font-weight: bold;
}

/* label elements inside form class should have same font */
.form label {
  font-family: 'Comfortaa';
  font-size: 14px;
}

  /* Custom CSS for dark border */
.card-border-dark {
  border: 1px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 1px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  transform: scale(1.02);
}



  
  .outer-page-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }
.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .runimage {
    height: "200px"; /* auto adjust the height */
    margin-left: "auto";
    margin-right: "auto";
  }
  .footer {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  /* set font size for all button elements on page */
  .btn {
    font-size: '18px';
  }


  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* width: 100%; */
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }
/*  */

/* General Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f5f7fa;
  color: #333;
  line-height: 1.6;
}

button {
  cursor: pointer;
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #3a7bc8;
}

button:disabled {
  background-color: #a9c0e2;
  cursor: not-allowed;
}

/* Login Styles */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f7fa;
}

.login-box {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 400px;
}

.login-box h1 {
  text-align: center;
  margin-bottom: 24px;
  color: #2e3e50;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group input:focus {
  border-color: #1976d2;
  outline: none;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.1);
}

.error-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  text-align: center;
}

.success-message {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  text-align: center;
}

/* Dashboard Styles */
.dashboard-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0 80px 0;
}

.dashboard-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  background-color: #4a90e2;
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-email {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
}

.dashboard-content {
  background-color: white;
  min-height: calc(100vh - 140px);
  padding: 12px;
}

/* Account section styles */
.account-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-profile {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.user-profile h2 {
  margin-bottom: 20px;
  color: #2e3e50;
  font-size: 22px;
}

.account-details {
  margin-bottom: 25px;
  text-align: left;
}

.account-email,
.account-role {
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
}

.account-email strong,
.account-role strong {
  display: inline-block;
  width: 80px;
  color: #7f8c8d;
}

.user-profile .logout-btn {
  background-color: #e74c3c;
  color: white;
  padding: 12px 20px;
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 6px;
}

.user-profile .logout-btn:hover {
  background-color: #c0392b;
}

/* Tab Navigation Styles */
.tab-navigation {
  display: flex;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eaeaea;
  background-color: #fff;
}

.tab-navigation button {
  flex: 1 1;
  padding: 15px;
  border-radius: 0;
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
  border: none;
  border-bottom: 3px solid transparent;
  transition: all 0.2s ease;
}

.tab-navigation button.active {
  background-color: #fff;
  border-bottom: 3px solid #4a90e2;
  color: #4a90e2;
}

.tab-navigation button:hover:not(.active) {
  background-color: #e9ecef;
}

.tab-content {
  min-height: 400px;
}

.actions-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 1200px;
  margin: 0 auto 20px;
  padding: 0 12px;
}

.approve-btn {
  background-color: #27ae60;
}

.approve-btn:hover {
  background-color: #219653;
}

.loading-spinner {
  text-align: center;
  padding: 30px;
  color: #7f8c8d;
}

.no-data {
  text-align: center;
  padding: 30px;
  color: #7f8c8d;
  background-color: #f9f9f9;
  border-radius: 4px;
}

/* User Card Styles */
.users-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}

.user-card {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  transition: box-shadow 0.3s ease;
}

.user-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.user-card.conflict {
  border-color: #e74c3c;
}

.user-header {
  display: flex;
  padding: 15px;
  background-color: #f8fafc;
  border-bottom: 1px solid #eaeaea;
}

.user-avatar {
  margin-right: 15px;
}

.user-avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.placeholder-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #4a90e2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.user-info h3 {
  margin-bottom: 5px;
  color: #2e3e50;
}

.conflict-badge {
  display: inline-block;
  background-color: #e74c3c;
  color: white;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 100px;
  margin-top: 8px;
}

.matches-container {
  padding: 15px;
}

.matches-container h4 {
  margin-bottom: 15px;
  color: #2e3e50;
  font-size: 16px;
}

.no-matches {
  color: #7f8c8d;
  font-style: italic;
  padding: 10px 0;
}

.db-user-card {
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.db-user-details {
  flex: 1 1;
}

.db-user-details div {
  margin-bottom: 6px;
  font-size: 14px;
}

.db-user-actions {
  margin-left: 15px;
}

button.selected {
  background-color: #27ae60;
}

/* Social Events Styles */
.events-actions {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
}

.days-filter {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.days-filter label {
  font-weight: 600;
}

.days-filter input {
  width: 70px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.action-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-left: auto;
}

.calendar-btn {
  background-color: #9c27b0;
}

.calendar-btn:hover {
  background-color: #7b1fa2;
}

.events-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 20px;
  gap: 20px;
  padding: 8px 4px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Event Cards */
.event-card {
  border: 1px solid #eaeaea;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
}

.event-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.event-card.expanded {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.event-card.posted {
  border-color: #27ae60;
  border-width: 2px;
}

.event-card.private {
  border-color: #f39c12;
  border-width: 2px;
}

.event-card button {
  position: relative;
  z-index: 2;
}

.event-header {
  padding: 16px 18px;
  background-color: #f8fafc;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.event-title {
  flex: 1 1;
}

.event-title h3 {
  margin-bottom: 5px;
  color: #2e3e50;
  font-size: 18px;
  line-height: 1.3;
}

.event-meta {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 8px;
  color: #666;
  font-size: 14px;
  line-height: 1.4;
}

.event-date {
  display: none; /* Hide the full date format */
}

.event-short-date {
  font-weight: 600;
  color: #4a90e2;
}

.event-time {
  color: #333;
}

.event-duration {
  color: #666;
  font-style: italic;
}

.event-actions {
  margin-left: 10px;
}

.event-badge {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.posted-badge {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.private-badge {
  background-color: #fff3e0;
  color: #e65100;
}

.event-details {
  padding: 16px 18px;
  line-height: 1.5;
}

.event-details > div {
  margin-bottom: 12px;
}

.event-location {
  font-size: 15px;
}

/* Event description with animation */
.event-description {
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
  margin-top: 10px;
  font-size: 14px;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.event-chat {
  font-size: 14px;
  color: #7f8c8d;
}

.event-id {
  margin-top: 10px;
  font-size: 12px;
  color: #95a5a6;
}

/* Calendar Results Styles */
.calendar-results {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
}

.calendar-results h3 {
  margin-bottom: 15px;
  color: #2e3e50;
}

.results-summary {
  margin-bottom: 20px;
  font-size: 16px;
}

.result-item {
  background-color: white;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  border-left: 4px solid #ddd;
}

.result-item.success {
  border-left-color: #27ae60;
}

.result-item.failure {
  border-left-color: #e74c3c;
}

.result-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.result-status {
  font-weight: 600;
}

.result-id {
  font-family: monospace;
  color: #7f8c8d;
}

.result-message,
.result-error {
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
}

.result-message {
  background-color: #f8f9fa;
  color: #495057;
}

.result-error {
  background-color: #ffebee;
  color: #d32f2f;
}

/* Cognito Authentication Styles */
.auth-selector {
  display: flex;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eaeaea;
}

.auth-selector button {
  flex: 1 1;
  padding: 12px;
  border-radius: 0;
  background-color: #f5f7fa;
  color: #2e3e50;
  font-weight: 600;
  border: none;
  border-bottom: 3px solid transparent;
}

.auth-selector button.active {
  background-color: #fff;
  border-bottom: 3px solid #4a90e2;
  color: #4a90e2;
}

.auth-selector button:hover:not(.active) {
  background-color: #edf2f7;
}

.cognito-login {
  text-align: center;
  padding: 20px 0;
}

.cognito-login p {
  margin-bottom: 20px;
  color: #7f8c8d;
}

.cognito-btn {
  background-color: #ff9900; /* AWS orange color */
  padding: 12px 20px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.cognito-btn:hover {
  background-color: #ec7211;
}

/* New login button style */
.login-btn {
  background-color: #4a90e2;
  padding: 12px 20px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}

.login-btn:hover {
  background-color: #3a7bc8;
}

/* Auth links styles */
.auth-links {
  margin-top: 20px;
  text-align: center;
  padding-top: 15px;
  border-top: 1px solid #eaeaea;
}

.signup-link {
  color: #4a90e2;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.signup-link:hover {
  color: #3a7bc8;
  text-decoration: underline;
}

.user-role {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.user-role.admin {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.user-role.member {
  background-color: #e3f2fd;
  color: #1976d2;
}

.user-role.user {
  background-color: #f5f5f5;
  color: #616161;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .users-list,
  .events-list {
    grid-template-columns: 1fr;
  }

  .actions-bar,
  .events-actions {
    flex-direction: column;
    align-items: stretch;
    grid-gap: 10px;
    gap: 10px;
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .dashboard-header {
    padding: 12px 15px;
  }

  .dashboard-header h1 {
    font-size: 18px;
  }

  .user-email {
    display: none; /* Hide email on mobile for cleaner header */
  }

  /* Hide the top tabs when bottom tabs are active */
  .tab-navigation {
    display: none;
  }

  /* Make event cards more compact on mobile */
  .event-card {
    margin-bottom: 15px;
  }

  .event-header {
    padding: 14px 16px;
  }

  .event-title h3 {
    font-size: 16px;
  }

  .event-details {
    padding: 14px 16px;
  }

  /* Make bottom tabs stick to bottom even when scrolling */
  .mobile-tab-navigation {
    position: fixed;
    bottom: 0;
    height: 60px;
  }

  .days-filter {
    flex-wrap: wrap;
  }

  .days-filter label {
    width: 100%;
  }

  .days-filter input {
    width: 100%;
  }

  .dashboard-content {
    padding: 8px;
  }

  .events-list {
    grid-template-columns: 1fr;
    grid-gap: 15px;
    gap: 15px;
    padding: 4px;
  }
}

/* For very small screens */
@media (max-width: 320px) {
  .mobile-tab-navigation button {
    padding: 5px 0;
  }

  .tab-icon {
    font-size: 20px;
  }

  .tab-label {
    font-size: 10px;
  }
}

.switch-auth-btn {
  background: none;
  border: none;
  color: #1976d2;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  padding: 5px;
  text-decoration: underline;
}

.switch-auth-btn:hover {
  color: #1565c0;
}

.password-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.password-input-container input[type="password"],
.password-input-container input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.show-password-label {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  padding: 4px 0;
  -webkit-user-select: none;
          user-select: none;
  line-height: 1;
}

.show-password-label input[type="checkbox"] {
  margin: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #1976d2;
  position: relative;
  top: 1px;
}

.show-password-label:hover {
  color: #1976d2;
}

.show-password-label:hover input[type="checkbox"] {
  accent-color: #1565c0;
}

.password-requirements {
  font-size: 12px;
  color: #666;
  margin-top: 8px;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.password-requirements ul {
  margin: 4px 0 0 20px;
  padding: 0;
}

.password-requirements li {
  margin: 2px 0;
  color: #666;
}

.password-requirements li::marker {
  color: #1976d2;
}

/* Verification form styles */
.verification-form {
  text-align: center;
  padding: 20px 0;
}

.verification-form h3 {
  margin-bottom: 15px;
  color: #2e3e50;
}

.verification-form p {
  margin-bottom: 20px;
  color: #7f8c8d;
}

.resend-code-btn {
  background-color: #95a5a6;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
}

.resend-code-btn:hover {
  background-color: #7f8c8d;
}

.resend-code-btn:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

/* Event styles */
.event-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.event-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.event-card:hover .event-image img {
  transform: scale(1.05);
}

/* Mobile App-like Bottom Navigation */
.mobile-tab-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
}

.mobile-tab-navigation button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: #95a5a6;
  border: none;
  padding: 8px 0;
  border-radius: 0;
  flex: 1 1;
  transition: color 0.3s ease;
}

.mobile-tab-navigation button.active {
  color: #4a90e2;
  background-color: transparent;
}

.tab-icon {
  font-size: 24px;
  margin-bottom: 4px;
}

.tab-label {
  font-size: 12px;
  font-weight: 500;
}

/* Empty state for new tabs */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  text-align: center;
}

.empty-state h2 {
  margin-bottom: 15px;
  color: #2e3e50;
}

.empty-state p {
  color: #7f8c8d;
  font-size: 16px;
}

/* Admin actions button */
.admin-actions {
  margin: 15px auto;
  max-width: 1200px;
  padding: 0 12px;
}

.admin-actions button {
  background-color: #9c27b0;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
}

.admin-actions button.active {
  background-color: #7b1fa2;
}

/* For small screens */
@media (max-width: 480px) {
  .events-list {
    padding: 0;
    grid-gap: 12px;
    gap: 12px;
  }

  .event-card {
    border-radius: 8px;
    margin-bottom: 0;
  }

  .event-badge {
    padding: 4px 8px;
    font-size: 11px;
  }
}

/* Event attendance indicator */
.event-attendance {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  color: #4a90e2;
  background-color: rgba(74, 144, 226, 0.1);
  padding: 2px 8px;
  border-radius: 20px;
}

.attendance-icon {
  margin-right: 4px;
  font-size: 13px;
}

/* See who's going button */
.see-attendees {
  margin-top: 15px;
}

.see-attendees-btn {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  transition: background-color 0.2s ease;
  text-align: center;
  cursor: pointer;
}

.see-attendees-btn:hover {
  background-color: #3a7bc8;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 420px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.3s ease;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: #4a90e2;
  color: white;
  border-radius: 8px 8px 0 0;
}

.modal-header h3 {
  margin: 0;
  font-size: 18px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-body {
  padding: 20px;
}

.modal-body p {
  margin-bottom: 16px;
  color: #333;
}

.modal-body ul {
  margin: 0 0 0 20px;
  padding: 0;
}

.modal-body li {
  margin-bottom: 10px;
  color: #666;
}

.modal-footer {
  padding: 15px 20px 20px;
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  justify-content: flex-end;
  border-top: 1px solid #eaeaea;
}

.subscribe-btn {
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
}

.cancel-btn {
  background-color: #f5f5f5;
  color: #666;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
}

.subscribe-btn:hover {
  background-color: #3a7bc8;
}

.cancel-btn:hover {
  background-color: #e5e5e5;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Subscription required screen */
.subscription-required {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.subscription-icon {
  font-size: 50px;
  margin-bottom: 20px;
}

.subscription-required h2 {
  color: #2e3e50;
  margin-bottom: 15px;
  font-size: 24px;
}

.subscription-required p {
  color: #666;
  margin-bottom: 15px;
  font-size: 16px;
}

.subscription-benefits {
  font-weight: 600;
  margin-top: 10px;
  color: #4a90e2;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.benefits-list li {
  background-color: #f8fafc;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: relative;
  padding-left: 30px;
  text-align: left;
}

.benefits-list li:before {
  content: "✓";
  color: #4a90e2;
  position: absolute;
  left: 12px;
  font-weight: bold;
}

.subscribe-btn-large {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0 3px 10px rgba(74, 144, 226, 0.3);
  transition: all 0.3s ease;
}

.subscribe-btn-large:hover {
  background-color: #3a7bc8;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(74, 144, 226, 0.4);
}

@media (max-width: 480px) {
  .subscription-required {
    padding: 30px 15px;
  }

  .benefits-list li {
    padding: 10px 10px 10px 30px;
    font-size: 14px;
  }

  .subscribe-btn-large {
    width: 100%;
  }
}

.home {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .runimage {
    height: "200px"; /* auto adjust the height */
    margin-left: "auto";
    margin-right: "auto";
  }
  .footer {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  /* set font size for all button elements on page */
  .btn {
    font-size: '18px';
  }


  .outer-home-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    /* width: 100%; */
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }
.page {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.Privacy {
  font-family: sans-serif;
  text-align: center;
  max-width: 480px;
  margin: 0 auto;
}
.btn, #tipAmount {
  font-size: 24px;
}
.lb-sm {
  font-size: 12px;
}

.lb-md {
  font-size: 16px;
}

.lb-lg {
  font-size: 20px;
}

.payBtn {
  height: 80px;
  font-size: 36px;
}
