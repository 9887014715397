.page {
    font-family: sans-serif;
    max-width: 450px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  /* .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    margin-top: 600px;

  } */
  
/* form-control */
.form-control {
  font-size: 14px;
  font-family: 'Comfortaa';
}

/* h3 elements inside form class need to have good font for bold text */
.form h3, .form label {
  font-family: 'Comfortaa';
  font-weight: bold;
}

/* label elements inside form class should have same font */
.form label {
  font-family: 'Comfortaa';
  font-size: 14px;
}

  /* Custom CSS for dark border */
.card-border-dark {
  border: 1px solid #d1d1d1; /* Replace with your desired dark color */
}
/* Custom CSS for dividing lines and text styles */
.card-divider {
  border-top: 1px solid #d1d1d1; /* Faint grey color */
  margin: 10px 0;
}

.card-text {
  text-align: justify;
  font-size: 14px;
  font-style: italic;
}

.custom-btn {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.custom-btn:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  transform: scale(1.02);
}



  
  .outer-page-container {
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100%;
    /* background-color: #333333;
    opacity: 0.7;
    filter:(opacity=50); */
  
    
  }

  .btn-default a {
    color: #333;
  }

  .btn-primary a {
    color: #FFF;
  }

  .btn-default a:hover{
    text-decoration: none;
  }
  .btn-primary a:hover{
    text-decoration: none;
  }