.pay {
  font-family: sans-serif;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.btn {
  width: 90%;
}